import { Component, Vue } from 'vue-property-decorator'
import FormObservationLegal from '@/components/LegalForm/Observation/Observation.vue'
import Crm from '@/Models/Crm'
import http from '@/services/http'
import Search from '@/components/Search/Search.vue'
import ObservationCrm from '@/Models/ObservationCrm'
import ModalDelete from '@/components/ModalDelete/ModalDelete.vue'
import * as Helpers from '@/helpers'
import Header from '@/components/Header/Header.vue'
import router from '@/router'
import menuModule from '@/store/menu'
import Animation from '@/components/Animation/index.vue'
@Component({
  components: {
    FormObservationLegal,
    Search,
    ModalDelete,
    Header,
    Animation,
  },
})
export default class ViewObservation extends Vue {
  showList = true
  data: Array<ObservationCrm> = []
  objObservationCrm: ObservationCrm = new ObservationCrm()
  action = 'add'
  member: Crm = new Crm()
  loading = false
  delete_modal_active = false
  //th de la tabla
  header: Array<any> = [
    {
      field: 'date_registered_read',
      label: 'Fecha',
    },
    {
      field: 'observation',
      label: 'Observacion',
    },
  ]
  mounted() {
    this.getObservationCrm()
  }
  async getObservationCrm() {
    this.loading = true
    try {
      const id = menuModule.id_param
      if (id == null) throw router.push({ name: 'CCSS' }).catch(console.log)
      const res = await http.get(`/api/legal/arcr_legal_observations/${id}/`)
      if (!res.data) throw new Error(res.data)
      this.member = res.data.member
      if (res.data.observations != 'No tiene observaciones') {
        this.data = res.data.observations.map((obj: any) => ({
          ...obj,
          user: this.member.member_number,
          date_registered_read: Helpers.dateFormat(obj.date_registered),
          date_registered: Helpers.dateParse(obj.date_registered),
        }))
      }
    } catch (error) {
      //console.error(error)
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
    this.loading = false
  }

  editObj(props: any) {
    this.objObservationCrm = props
    this.showList = false
    this.action = 'edit'
  }
  async deleteObj() {
    try {
      await http.delete(
        `/api/legal/arcr_legal_observations/${this.objObservationCrm.id}/`,
      )
      this.data = this.data.filter(
        (a: ObservationCrm) => a.id !== this.objObservationCrm.id,
      )
      this.delete_modal_active = false
      this.getObservationCrm()
    } catch (error) {
      console.log(error)
      //toast.error("An error has occurred please try again.")
    }
  }
  deleteConfirm(props: any) {
    this.delete_modal_active = true
    this.objObservationCrm = props
  }
  closeModal() {
    this.objObservationCrm = new ObservationCrm()
    this.delete_modal_active = false
  }
  openForm() {
    this.showList = false
  }
  async cleanForm() {
    ;(this.$refs.header as any).showList = true
    this.objObservationCrm = new ObservationCrm()
    this.showList = true
    this.action = 'add'
    await this.getObservationCrm()
  }
  isData(data: any) {
    this.data = data
  }
}
